(function ($) {
    function windowInnerWidth(){
        return (window.innerWidth || document.documentElement.clientWidth || 0);
    }
    function windowInnerHeight(){
        return (window.innerHeight || document.documentElement.clientHeight || 0);
    }
    $(function(){
        var bp ={};
        bp.sm = 992;
        bp.xs = 768;

        var uaCHK = [];
        uaCHK.ua = navigator.userAgent;
        uaCHK.touchdevice = /iphone|ipod|ipad|android/i.test(uaCHK.ua);
        if(uaCHK.touchdevice){
            $('body').addClass('touchdevice');
        }

        ////-----------------------------------------------------------------------------------------
        //// グローバルメニュー
        ////-----------------------------------------------------------------------------------------
        var $header = $('#gHeader'),
            $navBtn = $('#gHeader-nav-btn'),
            $gNav = $('#gHeader-mNav');

        $navBtn.on('click',function(){
            $header.toggleClass('menuOpen');
        });
        $gNav.on('click','a',function(){
            $header.removeClass('menuOpen');
            var _href = $(this).attr('href');
            _href = _href.split('#');
            if(_href.length > 1 && $('#'+_href[1]).length>0){
                id_scroll('#'+_href[1]);
                return false;
            }
        });


        var bodyPaddingTop = function() {
            $('body').css({
                'padding-top':$header.outerHeight(true)
            });
        };
        bodyPaddingTop();
        var lazybodyPaddingTop = _.debounce(bodyPaddingTop, 100);





        ////-----------------------------------------------------------------------------------------
        //// リサイズ
        ////-----------------------------------------------------------------------------------------
        /*function sp_setting(){
            // if (bp.xs <= windowInnerWidth()) {
            //     spmenuToggle = false;
            //     $gnav.removeAttr('style');
            //     $navBtn.removeClass('active');
            // }
        }
        sp_setting();
        var sp_setting_debounce = _.debounce(sp_setting,200);
        $(window).on('load',function(){
            sp_setting();
        }).on('resize',function(){
            sp_setting_debounce();
        });*/


        ////-----------------------------------------------------------------------------------------
        //// リサイズ
        ////-----------------------------------------------------------------------------------------
        function resize(){
            windowHeight = windowInnerHeight();
            lazybodyPaddingTop();
        }
        resize();
        var resize_debounce = _.debounce(resize,200);


        ////-----------------------------------------------------------------------------------------
        //// イベント
        ////-----------------------------------------------------------------------------------------
        $(window).on('load',function(){
            resize();
        }).on('resize',function(){
            resize_debounce();
        }).on('scroll',function(){
        });

        ////-----------------------------------------------------------------------------------------
        //// ライトボックス
        ////-----------------------------------------------------------------------------------------
        $('a[href$=".jpg"],a[href$=".jpeg"],a[href$=".png"],a[href$=".gif"],a[href$=".JPG"],a[href$=".JPEG"],a[href$=".PNG"],a[href$=".GIF"]').lightcase();
        $('a[data-rel^=lightcase]').lightcase();

        ////-----------------------------------------------------------------------------------------
        //// クリッカブルマップ
        ////-----------------------------------------------------------------------------------------
        $('img[usemap]').rwdImageMaps();



        ////-----------------------------------------------------------------------------------------
        //// ページ内アンカー
        ////-----------------------------------------------------------------------------------------
        function id_scroll(_href){
            if(_href.indexOf('=') < 0 && $(_href).length>0){
                console.log(_href);
                var speed = 500,
                    pos = 0;
                    hh = 0;
                if(_href !== '#gHeader'){
                    pos = $(_href).offset().top;
                    hh = $header.outerHeight();
                }
                $("html, body").animate({scrollTop:pos-hh}, speed, "swing");
            }
        }
        $('[data-scroll]').on('click',function(){
            var _href = $(this).attr('href');
            _href = _href.split('#');
            if(_href.length > 1 && $('#'+_href[1]).length>0){
                id_scroll('#'+_href[1]);
                return false;
            }
        });
        if(location.hash !== ''){
            $(window).on('load',function(){
                id_scroll(location.hash);
            });
        }






        ////-----------------------------------------------------------------------------------------
        //// SNSポップアップ
        ////-----------------------------------------------------------------------------------------
        $('a[data-sns]').on('click',function(){
            var url = $(this).attr('href');
            var para = {
                url:'url=',
                title:''
            };
            if($(this).attr('data-sns') == 'TW'){
                para.url = 'url=';
                para.title = '&amp;text=';
            }else if($(this).attr('data-sns') == 'FB'){
                para.url = 'u=';
                para.title = '&amp;t=';
            }
            if($(this).attr('data-sns-url')){
                console.log('data-sns-url',$(this).attr('data-sns-url'));
                url += para.url + $(this).attr('data-sns-url');
            }
            if($(this).attr('data-sns-title')){
                console.log('data-sns-url',$(this).attr('data-sns-url'));
                url += para.title + $(this).attr('data-sns-title');
            }else if($(this).attr('data-getTitle') == 'yes'){
                url += para.url + encodeURI(location.href);
                url += para.title  + encodeURI($('title').text());
            }

            var win=window.open(url, 'popup', 'width=650, height=380, menubar=no, toolbar=no, scrollbars=yes,resizable=yes,status=no');
            win.focus();
            return false;
        });
    });
})(window.jQuery);
